import React from 'react'
import { Typography, Button } from '@mui/material';
import { useSpring, animated } from 'react-spring';
import { Box } from '@mui/material'
import { useState } from 'react';
import { useEffect } from 'react';

export default function Home() {


  const [images, setImages] = useState([
    './images/quantum-1.jpg',
    './images/quantum-2.jpg',
    './images/quantum-3.jpg',
  ]);
  const [randomImageUrl, setRandomImageUrl] = useState('');
  const [backgroundImage, setBackgroundImage] = useState(`url(${randomImageUrl})`);
  useEffect(() => {
    const setRandomImage = () => {
      const randomIndex = Math.floor(Math.random() * images.length);
      const randomImage = images[randomIndex];
      setBackgroundImage(`url(${randomImage})`);
    };
    // Set a random image when the component mounts
    setRandomImage();

    const intervalId = setInterval(setRandomImage, 3000);
    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  const fadeIn = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 },
  });

  const fadeInOut = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    reset: true,
  });

  return (
    <>
      <div
        style={{
          textAlign: 'center',
          padding: '50px',
          backgroundImage: backgroundImage,
          backgroundSize: 'cover',
          height: '100vh',
        }}
      >
        <animated.div style={fadeInOut}>

          <nav className="navbar navbar-expand-lg">
          
            <div className="collapse navbar-collapse  justify-content-end" id="navbarNav">
              <ul className="navbar-nav">
                
                <li className="nav-item">
                  <a className="nav-link" href="admin">Login</a>
                </li>
                
              </ul>
            </div>
          </nav>


          <Typography variant="h2" gutterBottom className='text-white'>
            Welcome to Intrust Innovation Labs
          </Typography>
          <Typography variant="h1" gutterBottom className='text-success'>
            Pioneering the Future of Digital Security
          </Typography>

        </animated.div>
      </div>
    </>
  )
}