import { Close } from '@material-ui/icons'
import { Box, Button, Grid, IconButton, TextField, TextareaAutosize, Typography } from '@mui/material'
import React, { useState } from 'react'
import Swal from "sweetalert2";
import axios from 'axios';
function AddProduct({ closeEvent }) {
    const [insertData, setInsertData] = useState([])
    const createData = async () => {
        closeEvent()
        try {
            // Assuming your server endpoint is '/api/login'
            const response = await axios.post('http://localhost/api/createUser.php', insertData);

            // Check the response from the server and handle accordingly
            if (!response.data.error) {
                Swal.fire("Success", "User Created Successfully", "success")
                    .then((result) => {
                        // navigate('/dashboard');
                        Swal.fire("Success", "User Created Successfully", "success")
                    })
                // Redirect or perform actions after successful login
            } else {
                console.log('Login failed');
                // Handle unsuccessful login (display error message, etc.)
                Swal.fire("Error", "Error in user creation", "error")
            }
        } catch (error) {
            console.error('Error during login:', error.message);
            // Handle error, e.g., display a generic error message
        }
    }
    const handleInputChange = (event) => {
        setInsertData({
            ...insertData, [event.target.name]: event.target.value
        })
    }

    return (
        <>
            <Typography variant='h5' align='right'>
                <IconButton
                    style={{ production: "absolute", top: "0", right: "0" }}
                    onClick={closeEvent}>
                    <Close />
                </IconButton>
            </Typography>
            <Typography variant='h5' align='center'>
                Add Product
            </Typography>
            <Box height={20} />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField value={insertData?.name} onChange={(e) => handleInputChange(e)}
                        label="Name" name="name" variant='outlined' size='small' sx={{ minWidth: "100%" }} />
                </Grid>
                <Grid item xs={12}>
                    <TextField value={insertData?.email} onChange={(e) => handleInputChange(e)}
                        label="Email" name="email" variant='outlined' size='small' sx={{ minWidth: "100%" }} />
                </Grid>
                <Grid item xs={12}>
                    <TextField value={insertData?.mobile} onChange={(e) => handleInputChange(e)}
                        label="Mobile" name="mobile" variant='outlined' size='small' sx={{ minWidth: "100%" }} />
                </Grid>
                <Grid item xs={12}>
                    <TextField value={insertData?.password} onChange={(e) => handleInputChange(e)}
                        label="Password" name="password" variant='outlined' size='small' sx={{ minWidth: "100%" }} />
                </Grid>

                <Grid item xs={12}>
                    <Button variant="contained" onClick={createData}>Submit</Button>
                </Grid>

            </Grid>
        </>

    )
}

export default AddProduct