import React from 'react'

import NavBar from '../components/NavBar'
import { Box, Button, Typography } from '@mui/material'
import { deepPurple } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';

const primary = deepPurple[500]; // #f44336
export default function PageNotFound() {
  const navigate = useNavigate();
  return (
    <>
      <NavBar />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          minHeight: '100vh',
          backgroundColor: primary,
        }}
      >
        <Typography variant="h1" style={{ color: 'white' }}>
          404
        </Typography>
        <Typography variant="h6" style={{ color: 'white' }}>
          The page you’re looking for doesn’t exist.
        </Typography>
        <Button variant="contained" onClick={() => navigate(-1)}>Back</Button>
      </Box>
    </>
  )
}