import React from 'react'
import ListUser from './users/ListUser'
import { Box } from '@mui/material'
import NavBar from '../components/NavBar'
import Sidenav from '../components/Sidenav'

export default function Products() {
  return (
    <>
      <NavBar  title="" />
      <Box marginTop={10}>
        <Box sx={{ display: "flex" }}>
          <Sidenav />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <ListUser />
          </Box>
        </Box>
      </Box>
    </>
  )
}