import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';

import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';

import { Box, IconButton } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FactoryIcon from '@mui/icons-material/Factory';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import LanguageIcon from '@mui/icons-material/Language';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import BusinessIcon from '@mui/icons-material/Business';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';

import useBearStore from '../state/state';
const drawerWidth = 240;



const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function SidenavUsecase() {
  const { sideNavOpen } = useBearStore((state) => state);
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const handleSubmenuItemClicked = (path) => {
    navigate(path)
  };
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Box height={30}>
          <Drawer variant="permanent" open={sideNavOpen}>
            <DrawerHeader>
              <IconButton>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              {/* Dashboard */}
              <ListItem key="Industries" disablePadding sx={{ display: 'block' }} onClick={() => navigate('/usecase')}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 1.5,
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                    <FactoryIcon />
                  </ListItemIcon>
                  <ListItemText primary="Industries" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
              {/* platform menus */}
              <ListItem key="BankingFinance" disablePadding sx={{ display: 'block' }} onClick={() => handleSubmenuItemClicked('/usecase/banking-finance')}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 1.5,
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                    <AccountBalanceIcon />
                  </ListItemIcon>
                  <ListItemText primary="Banking & Finance" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>

              <ListItem key="Healthcare" disablePadding sx={{ display: 'block' }} onClick={() => handleSubmenuItemClicked('/usecase/healthcare')}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 1.5,
                    '&:hover': {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                    <LocalHospitalIcon />
                  </ListItemIcon>
                  <ListItemText primary="Healthcare" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>

              <ListItem key="Energy Sector" disablePadding sx={{ display: 'block' }} onClick={() => handleSubmenuItemClicked('/usecase/energy-sector')}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 1.5,
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                    <EnergySavingsLeafIcon />
                  </ListItemIcon>
                  <ListItemText primary="Energy Sector" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>

              <ListItem key="Transportation" disablePadding sx={{ display: 'block' }} onClick={() => handleSubmenuItemClicked('/usecase/transportation')}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 1.5,
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                    <EmojiTransportationIcon />
                  </ListItemIcon>
                  <ListItemText primary="Transportation" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>

              <ListItem key="Information Technology" disablePadding sx={{ display: 'block' }} onClick={() => handleSubmenuItemClicked('/usecase/information-technology')}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 1.5,
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                    <LanguageIcon />
                  </ListItemIcon>
                  <ListItemText primary="Information Technology" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>

              <ListItem key="Govt. and Defence" disablePadding sx={{ display: 'block' }} onClick={() => handleSubmenuItemClicked('/usecase/govt-defence')}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 1.5,
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                    <AssuredWorkloadIcon />
                  </ListItemIcon>
                  <ListItemText primary="Govt. and Defence" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
              <ListItem key="Telecom & Data Center" disablePadding sx={{ display: 'block' }} onClick={() => handleSubmenuItemClicked('/usecase/telecom-data-center')}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 1.5,
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                    <SatelliteAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="Telecom & Data Center" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
              <ListItem key="Emerging Industries" disablePadding sx={{ display: 'block' }} onClick={() => handleSubmenuItemClicked('/usecase/emerging-industries')}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 1.5,
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                    <BusinessIcon />
                  </ListItemIcon>
                  <ListItemText primary="Emerging Industries" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
              <ListItem key="Water & Waste Digital" disablePadding sx={{ display: 'block' }} onClick={() => handleSubmenuItemClicked('/usecase/water-waste-digital')}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 1.5,
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                    <DeleteSweepIcon />
                  </ListItemIcon>
                  <ListItemText primary="Water & Waste Digital" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </List>
            <Divider />
          </Drawer>
        </Box>
      </Box>
    </>
  );
}
