
import React from 'react'
import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import NavBar from '../../components/NavBar'
import SidenavSecurity from '../../components/SidenavSecurity'


export default function SecurityAudit() {

    return (
        <>
            <NavBar title="Next-Gen Cybersecurity Audit" />
            <Box marginTop={10}>
                <Box sx={{ display: "flex" }}>
                    <SidenavSecurity />
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h1>Security Audit</h1>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

        </>
    )
}

