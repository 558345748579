import React from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';
import Home from './pages/Home'
import About from './pages/About'
import Settings from './pages/Settings'
import Dashboard from './pages/Dashboard'

import Users from './pages/Users'
import ForgetPassword from './pages/ForgetPassword'
import Login from './pages/Login'
import Landing from './pages/Landing'
import PageNotFound from './pages/PageNotFound'



import Blockchain from './poc/products/Blockchain';
import NatureInspired from './poc/products/NatureInspired';
import AICrypto from './poc/products/AICrypto';
import PostQuantum from './poc/products/PostQuantum';
import Quantum from './poc/products/Quantum';
import QuantumSafeSuite from './poc/products/QuantumSafeSuite';
import Web3 from './poc/products/Web3';
import CustomCrypto from './poc/products/CustomCrypto';

import Products from './poc/products/Products';
import Platforms from './poc/platforms/Platforms';
import TestSuite from './poc/testsuite/TestSuite';
import Usecase from './poc/usecase/Usecase';
import SecurityAudit from './poc/securityaudit/SecurityAudit';

import CAVP from './poc/platforms/CAVP';
import UniPay from './poc/platforms/UniPay';
import UniOps from './poc/platforms/UniOps';
import VAPT from './poc/platforms/VAPT';
import Parallax from './widgets/Parallax';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="" element={<App />}>
          <Route path="" element={<Login />}></Route>
          <Route path="login" element={<Login />}></Route>
          <Route path="settings" element={<Settings />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="users" element={<Users />} />

          <Route path="products" element={<Products />} />
          <Route path="products/blockchain" element={<Blockchain />} />
          <Route path="products/nature-inspired" element={<NatureInspired />} />
          <Route path="products/ai-crypto" element={<AICrypto />} />
          <Route path="products/post-quantum" element={<PostQuantum />} />
          <Route path="products/quantum" element={<Quantum />} />
          <Route path="products/quantum-safe-suite" element={<QuantumSafeSuite />} />
          <Route path="products/web3" element={<Web3 />} />
          <Route path="products/custom-crypto-and-protocols" element={<CustomCrypto />} />


          <Route path="platforms" element={<Platforms />} />
          <Route path="platforms/cavp" element={<CAVP />} />
          <Route path="platforms/unipay" element={<UniPay />} />
          <Route path="platforms/uniops" element={<UniOps />} />
          <Route path="platforms/vapt" element={<VAPT />} />

          <Route path="testsuite" element={<TestSuite />} />

          <Route path="usecase" element={<Usecase />} />
          <Route path="usecase" element={<Usecase />} />
          <Route path="usecase" element={<Usecase />} />
          <Route path="usecase" element={<Usecase />} />


          <Route path="security-audit" element={<SecurityAudit />} />


        </Route>

        <Route path="/landing" exact element={<Landing />}></Route>
        <Route path="/" exact element={<Home />}></Route>
        <Route path="home" exact element={<Home />}></Route>
        <Route path="forget-password" element={<ForgetPassword />}></Route>

        <Route path="about" element={<About />}></Route>
        <Route path="web" element={<Parallax />}></Route>
        <Route path="*" element={<PageNotFound />}></Route>

      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
