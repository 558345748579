import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Autocomplete, Box, Divider, Stack, TextField, Typography, Button } from '@mui/material';
import axios from "axios";
import Swal from "sweetalert2";
import { AddCircle, Delete, Edit } from '@material-ui/icons';
import Modal from '@mui/material/Modal';
import AddUser from './AddUser';
import EditUser from './EditUser';
import Skeleton from '@mui/material/Skeleton';

const baseURL = "http://localhost/api/getUsers.php";
const columns = [
    { id: 'id', label: 'Id', minWidth: 170 },
    { id: 'name', label: 'Name', minWidth: 100 },
    { id: 'email', label: 'Email', minWidth: 100 },
    { id: 'mobile', label: 'Mobile', minWidth: 100 },
    { id: 'status', label: 'Status', minWidth: 100 },
    { id: 'createdAt', label: 'Created', minWidth: 100 },
];



export default function UserList() {
    const [users, setUsers] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [open, setOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [editData, setEditData] = React.useState("");

    const handleClose = () => setOpen(false);

    React.useEffect(() => {
        getUsers()
    }, []);


    const getUsers = () => {
        axios.get(baseURL).then((response) => {
            console.log(response.data.data);
            setUsers(response.data.data.map((row) => ({ ...row, id: row.id })));
        })
    };

    const deleteUser = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    title: "Delete",
                    text: "Deleted Successfully!",
                    icon: "success"
                });
            }
        });
    };

    const filterData = (v) => {
        if (v) {
            setUsers([v]);
        } else {
            getUsers();
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const addUser = () => {
        
    }
    const editUser = (data) => {
        setEditData(data)
        setEditOpen(true)
    }
    const handleEditClose = () => {
        setEditOpen(false)
    }

    const TableRowsLoader = ({ rowsNum }) => {
        return [...Array(rowsNum)].map((row, index) => (
            <TableRow key={index}>
                {columns.map((column) => (
                    <TableCell>
                        <Skeleton animation="wave" variant="text" />
                    </TableCell>
                ))}
            </TableRow>
        ));
    };

    return (
        <>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"                >
                    <Box sx={style}>
                        <AddUser closeEvent={handleClose} />
                    </Box>
                </Modal>
                <Modal
                    open={editOpen}
                    onClose={handleEditClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"                >
                    <Box sx={style}>
                        <EditUser closeEvent={handleEditClose} formData={editData} />
                    </Box>
                </Modal>

                <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2 }}>
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{ padding: "20px" }}
                        onClick={editUser}
                    >
                        Users List
                    </Typography>
                    <Divider />
                    <Box height={10} />
                    <Stack direction="row" spacing={2} className="my-2 mb-2">
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={users}
                            sx={{ width: 300 }}
                            onChange={(e, v) => filterData(v)}
                            getOptionLabel={(users) => users.name || ""}
                            renderInput={(params) => (
                                <TextField {...params} size="small" label="Search Users" />
                            )}
                        />
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 1 }}
                        ></Typography>
                        <Button variant="contained" endIcon={<AddCircle />} onClick={addUser}>
                            Add
                        </Button>
                    </Stack>

                    <Divider />

                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.length > 0 && users
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.id}
                                            >
                                                <TableCell align="left">{row.id}</TableCell>
                                                <TableCell align="left">{row.name}</TableCell>
                                                <TableCell align="left">{row.email}</TableCell>
                                                <TableCell align="left">{row.mobile}</TableCell>
                                                <TableCell align="left">{row.status}</TableCell>
                                                <TableCell align="left">
                                                    <Stack spacing={2} direction="row">
                                                        <Edit
                                                            style={{
                                                                fontSize: "20px",
                                                                color: "blue",
                                                                cursor: "pointer",
                                                            }}
                                                            className="cursor-pointer"
                                                            onClick={() => editUser(row)}
                                                        />
                                                        <Delete
                                                            style={{
                                                                fontSize: "20px",
                                                                color: "darkred",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                deleteUser(row.id);
                                                            }}
                                                        />
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}

                                {users.length === 0 && (
                                    <TableRowsLoader rowsNum={5} />
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>



                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        component="div"
                        count={users.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
      
        </>

    );
}