import React from 'react'
import SidenavPlatform from '../../components/SidenavPlatform'
import NavBar from '../../components/NavBar'
import { Box } from '@mui/material'
import List from './List'

export default function UniPay() {
  return (
    <>
      <NavBar title="Next-Gen Platforms" />
      <Box marginTop={10}>
        <Box sx={{ display: "flex" }}>
          <SidenavPlatform />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <h1>UniPay</h1>
            <List />
          </Box>
        </Box>
      </Box>
    </>
  )
}