import {  Outlet } from 'react-router-dom'
// import useBearStore from './state/state'
import { ThemeProvider } from '@mui/material/styles';
import theme from './utils/theme';
export default function App() {
  // const isLoggedIn = useBearStore((state) => state.isLoggedIn)
  return (

    <>
      <ThemeProvider theme={theme}>
        <Outlet />
      </ThemeProvider>

      {/* {isLoggedIn ? <Outlet /> : <Navigate to={"login"} />} */}
    </>
  )
}