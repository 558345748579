
import React from 'react'
import { Box, Card, CardActions, CardContent, Grid, Stack, Typography, Button } from '@mui/material'

import { CreditCard, Report } from '@material-ui/icons'
import { ShoppingBag } from '@mui/icons-material'

export default function ListItems() {

    return (
        <>
            <Grid item xs={9}>
                <Stack spacing={2} direction="row">
                    <Card sx={{ minWidth: 33 + "%", height: 230 }} className='gradien'>
                        <CardContent>
                            <div className='text-white'>
                                <CreditCard />
                            </div>
                            <Typography gutterBottom variant="h5" component="div" className='mt-2'>
                                CAVP
                            </Typography>
                            <Typography gutterBottom variant="body2" component="div" sx={{ color: "#ccd1d1" }}  >
                                Intrust Innovation Labs is steadfast committed to pioneering the evolution of cryptography solutions, fortifying security, and safeguarding against future cyber attacks.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small">Learn More</Button>
                        </CardActions>
                    </Card>
                    <Card sx={{ minWidth: 33 + "%", height: 230 }} className='gradien'>
                        <CardContent>
                            <div className='text-white'>
                                <CreditCard />
                            </div>
                            <Typography gutterBottom variant="h5" component="div" className='mt-2'>
                                UniPay
                            </Typography>
                            <Typography gutterBottom variant="body2" component="div" sx={{ color: "#ccd1d1" }}  >
                                Intrust Innovation Labs is steadfast committed to pioneering the evolution of cryptography solutions, fortifying security, and safeguarding against future cyber attacks.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small">Learn More</Button>
                        </CardActions>
                    </Card>
                </Stack>
                <Stack spacing={2} direction="row" marginY={3}>
                    <Card sx={{ minWidth: 33 + "%", height: 230 }} className='gradien'>
                        <CardContent>
                            <div className='text-white'>
                                <CreditCard />
                            </div>
                            <Typography gutterBottom variant="h5" component="div" className='mt-2'>
                                UniOps
                            </Typography>
                            <Typography gutterBottom variant="body2" component="div" sx={{ color: "#ccd1d1" }}  >
                                Intrust Innovation Labs is steadfast committed to pioneering the evolution of cryptography solutions, fortifying security, and safeguarding against future cyber attacks.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small">Learn More</Button>
                        </CardActions>
                    </Card>
                    <Card sx={{ minWidth: 33 + "%", height: 230 }} className='gradien'>
                        <CardContent>
                            <div className='text-white'>
                                <CreditCard />
                            </div>
                            <Typography gutterBottom variant="h5" component="div" className='mt-2'>
                                VAPT
                            </Typography>
                            <Typography gutterBottom variant="body2" component="div" sx={{ color: "#ccd1d1" }}  >
                                Intrust Innovation Labs is steadfast committed to pioneering the evolution of cryptography solutions, fortifying security, and safeguarding against future cyber attacks.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small">Learn More</Button>
                        </CardActions>
                    </Card>

                </Stack>
            </Grid>


        </>
    )
}

