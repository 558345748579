// src/utils/theme.jsx

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2196f3', // Change this to the desired primary color (e.g., blue)
        },
        // You can customize secondary, error, warning, info, and success colors as well
    },
    // Add other customizations like typography, spacing, etc. as needed
});

export default theme;
