import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';

import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';

import { Box, Collapse, IconButton } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PeopleIcon from '@mui/icons-material/People';
import useBearStore from '../state/state';
const drawerWidth = 240;



const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Sidenav() {
  const { sideNavOpen, setSideNavOpen, productsOpen, setProductsOpen,
    platformsOpen, setPlatformsOpen,
    testsuiteOpen, setTestsuiteOpen,
    logout } = useBearStore((state) => state);
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [submenuOpen, setSubmenuOpen] = React.useState(false);
  const [activeSubmenuItem, setActiveSubmenuItem] = React.useState(null);

  const navigate = useNavigate();

  const products = [
    'Post Quantum',
    'Quantum Crypto',
    'Quantum Safe Suite',
    'Nature Inspired',
    'Web3',
    'Blockchain'
  ]

  const handleLogout = () => {
    logout();
    navigate('/login')
  };
  const handleToggleSubmenu = () => {
    setProductsOpen(!productsOpen);
    setSubmenuOpen(!productsOpen);
  };
  const handleToggleSubmenuplatfrom = () => {
    setPlatformsOpen(!platformsOpen);
    setSubmenuOpen(!platformsOpen);
  };
  const handleToggleSubmenutestsuite = () => {
    setTestsuiteOpen(!testsuiteOpen);
    setSubmenuOpen(!testsuiteOpen);
  };
  const handleSubmenuItemClicked = (path) => {
    setActiveSubmenuItem(path); // Set the active submenu item
    setSubmenuOpen(true); // Close the submenu when a submenu item is clicked
    navigate(path)
  };



  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Box height={30}>
          <Drawer variant="permanent" open={sideNavOpen}>
            <DrawerHeader>
              <IconButton>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              {/* Dashboard */}
              <ListItem key="Dashboard" disablePadding sx={{ display: 'block' }} onClick={() => navigate('/dashboard')}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 1.5,
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>

              {/* Dashboard */}
              {/* <ListItem key="ProductList" disablePadding sx={{ display: 'block' }} onClick={() => navigate('/products')}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 1.5,
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Product List" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem> */}

              {/* Products */}
              <ListItem key="products" disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 1.5,
                  }}
                  onClick={handleToggleSubmenu}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Products" sx={{ opacity: open ? 1 : 0 }} />
                  {productsOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </ListItemButton>
                <Collapse in={productsOpen}>
                  <List>

                    {/* Products menus */}


                    <ListItem key="post-quantum" disablePadding sx={{ display: 'block' }} onClick={() => handleSubmenuItemClicked('/products/post-quantum')}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 4,
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                          <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="PQC" sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>

                    <ListItem key="quantum" disablePadding sx={{ display: 'block' }} onClick={() => handleSubmenuItemClicked('/products/quantum')}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 4,
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                          <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="QC" sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>

                    <ListItem key="quantum-safe-suite" disablePadding sx={{ display: 'block' }} onClick={() => handleSubmenuItemClicked('/products/quantum-safe-suite')}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 4,
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                          <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="QSCPS" sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>

                    <ListItem key="nature-inspired" disablePadding sx={{ display: 'block' }} onClick={() => handleSubmenuItemClicked('/products/nature-inspired')}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 4,
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                          <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="NIC" sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>

                    <ListItem key="AIC" disablePadding sx={{ display: 'block' }} onClick={() => handleSubmenuItemClicked('/products/ai-crypto')}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 4,
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                          <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="AIC" sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>

                    <ListItem key="web3" disablePadding sx={{ display: 'block' }} onClick={() => handleSubmenuItemClicked('/products/web3')}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 4,
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                          <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="Web3" sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>

                    <ListItem key="blockchain" disablePadding sx={{ display: 'block' }} onClick={() => handleSubmenuItemClicked('/products/blockchain')}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 4,
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                          <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="Blockchain" sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Collapse>
              </ListItem>

              {/* Platforms */}
              <ListItem key="platforms" disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 1.5,
                  }}
                  onClick={handleToggleSubmenuplatfrom}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Platforms" sx={{ opacity: open ? 1 : 0 }} />
                  {platformsOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </ListItemButton>
                <Collapse in={platformsOpen}>
                  <List>
                    {/* platform menus */}
                    <ListItem key="cavp" disablePadding sx={{ display: 'block' }} onClick={() => handleSubmenuItemClicked('/platforms/cavp')}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 4,
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                          <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="CAVP" sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>

                    <ListItem key="unipay" disablePadding sx={{ display: 'block' }} onClick={() => handleSubmenuItemClicked('/platforms/unipay')}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 4,
                          '&:hover': {
                            backgroundColor: theme.palette.action.hover,
                          },
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                          <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="UniPay" sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>

                    <ListItem key="uniops" disablePadding sx={{ display: 'block' }} onClick={() => handleSubmenuItemClicked('/platforms/uniops')}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 4,
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                          <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="UniOps" sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>

                    <ListItem key="vapt" disablePadding sx={{ display: 'block' }} onClick={() => handleSubmenuItemClicked('/platforms/vapt')}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 4,
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                          <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="VAPT" sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Collapse>
              </ListItem>

              {/* Test Suite */}
              <ListItem key="suite" disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 1.5,
                  }}
                  onClick={handleToggleSubmenutestsuite}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Test Suite" sx={{ opacity: open ? 1 : 0 }} />
                  {testsuiteOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </ListItemButton>
                <Collapse in={testsuiteOpen}>
                  <List>
                    {/* platform menus */}
                    <ListItem key="cavp" disablePadding sx={{ display: 'block' }} onClick={() => handleSubmenuItemClicked('/platforms/cavp')}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 4,
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                          <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="CAVP" sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>

                    <ListItem key="unipay" disablePadding sx={{ display: 'block' }} onClick={() => handleSubmenuItemClicked('/platforms/unipay')}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 4,
                          '&:hover': {
                            backgroundColor: theme.palette.action.hover,
                          },
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                          <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="UniPay" sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>

                    <ListItem key="uniops" disablePadding sx={{ display: 'block' }} onClick={() => handleSubmenuItemClicked('/platforms/uniops')}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 4,
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                          <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="UniOps" sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>

                    <ListItem key="vapt" disablePadding sx={{ display: 'block' }} onClick={() => handleSubmenuItemClicked('/platforms/vapt')}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 4,
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                          <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="VAPT" sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Collapse>
              </ListItem>

              {/* Logout */}
              {/* <ListItem key="logout" disablePadding sx={{ display: 'block' }} onClick={() => handleLogout()}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 1.5,
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logout" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem> */}
            </List>
            <Divider />
          </Drawer>
        </Box>
      </Box>
    </>
  );
}
