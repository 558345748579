// api/apiService.js

import axios from 'axios';

const API_BASE_URL = 'https://admin.intrustlabs.com/api/';

const apiService = axios.create({
  baseURL: API_BASE_URL,
});

// Example GET request
const fetchData = async (endpoint) => {
  try {
    const response = await apiService.get(endpoint);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

// Example POST request
const postData = async (data) => {
  try {
    // const response = await apiService.get(endpoint, data);
    if (data.email == "admin@intrustlabs.com" && data.password == "Admin@123") {
      const result = {
        "message": "Login Success",
        "error": false,
        "data": data,
      }

      return result;
    } else {
      return {
        "message": "Invalid Credentials",
        "error": true,
        "data": [],
      }
    }


  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export { fetchData, postData };
