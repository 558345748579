import { Close } from '@material-ui/icons'
import { Box, Button, Grid, IconButton, TextField, TextareaAutosize, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react';
import Swal from "sweetalert2";


export default function EditProduct({ closeEvent, formData }) {
    const [updateData, setUpdateData] = useState(formData);
    const handleInputChange = (event) => {
        setUpdateData({
            ...updateData, [event.target.name]: event.target.value
        })
    }

    useEffect(()=>{
        console.log("Edit ",formData)
    })

    const editProduct = () => {
        closeEvent()
        Swal.fire("Success", "User Updated Successfully", "success")
    }


    return (
        <>
            <Typography variant='h5' align='right'>
                <IconButton
                    style={{ production: "absolute", top: "0", right: "0" }}
                    onClick={closeEvent}>
                    <Close />
                </IconButton>
            </Typography>
            <Typography variant='h5' align='center'>
                Edit User
            </Typography>
            <Box height={20} />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField value={updateData?.name} onChange={(e) => handleInputChange(e)}
                        label="Name" variant='outlined' size='small' sx={{ minWidth: "100%" }} />
                </Grid>
                <Grid item xs={12}>
                    <TextField value={updateData?.email} onChange={(e) => handleInputChange(e)}
                        label="Email" variant='outlined' size='small' sx={{ minWidth: "100%" }} />
                </Grid>
                <Grid item xs={12}>
                    <TextField value={updateData?.mobile} onChange={(e) => handleInputChange(e)}
                        label="Mobile" variant='outlined' size='small' sx={{ minWidth: "100%" }} />
                </Grid>
                <Grid item xs={12}>
                    <TextField value={updateData?.password} onChange={(e) => handleInputChange(e)}
                        label="Password" variant='outlined' size='small' sx={{ minWidth: "100%" }} />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" onClick={editProduct}>Submit</Button>
                </Grid>

            </Grid>
        </>

    )
}
