import React, { useState } from 'react';
import { Grid, Paper, Avatar, TextField, Button, Typography, Link } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';


import { Box } from '@mui/material';

export default function ForgetPassword() {
  
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);

    const handleReset = () => {
        // Add your login logic here using the entered Email, password, and rememberMe state.
        console.log(email)
        console.log('Logging in...');
    };
    const paperStyle = {
        padding: 20,
        width: 320,
        margin: "auto", // Centering the form horizontally
        marginTop: "100px", // Adjust the top margin as needed
    };
    const avatarStyle = { backgroundColor: '#1bbd7e' }
    const btnstyle = { margin: '8px 0' }

    return (
        <Grid>
            <Paper elevation={10} style={paperStyle}>
                <Grid align='center'>
                    <Avatar style={avatarStyle}><LockOutlinedIcon /></Avatar>
                    <Box marginTop={2} />
                    <h2>Recover password</h2>
                </Grid>
                <Box marginTop={2} />
                <TextField
                    label='Email'
                    placeholder='Enter Email'
                    fullWidth
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Box marginTop={2} />
                <Button
                    type='submit'
                    color='primary'
                    variant="contained"
                    style={btnstyle}
                    fullWidth
                    onClick={handleReset}
                >
                    Recover
                </Button>
                <Box marginTop={2} />
                <Typography align='center'>
                    <Link href="/" >
                        Sign In
                    </Link>
                </Typography>

            </Paper >
        </Grid>

    );
};
