
import React from 'react'
import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import NavBar from '../../components/NavBar'
import SidenavTestSuite from '../../components/SidenavTestSuite'
import { CreditCard } from '@material-ui/icons'

export default function TestSuite() {

    const cardStyle = {

    };

    return (
        <>
            <NavBar title="Product Test Suites" />
            <Box marginTop={10}>
                <Box sx={{ display: "flex" }}>
                    <SidenavTestSuite />
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h1>Product Test Suite</h1>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

        </>
    )
}

