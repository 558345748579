
import React from 'react'
import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import NavBar from '../../components/NavBar'
import SidenavPlatform from '../../components/SidenavPlatform'

import { CreditCard } from '@material-ui/icons'


export default function Platforms() {

    return (
        <>
            <NavBar title="Next-Gen Platforms" />
            <Box marginTop={10}>
                <Box sx={{ display: "flex" }}>
                    <SidenavPlatform />
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Stack spacing={2} direction="row">
                                    <Card sx={{  }} className=''>
                                        <CardContent>
                                            <div className='text-white'>
                                                <CreditCard />
                                            </div>
                                            <Typography gutterBottom variant="h5" component="div" className='mt-2'>
                                                Post Quantum Cryptography (PQC)
                                            </Typography>
                                            <Typography gutterBottom variant="body2" component="div" sx={{ color: "#ccd1d1" }} paddingBottom={5} >
                                                Intrust Innovation Labs is steadfast committed to pioneering the evolution of cryptography solutions, fortifying security, and safeguarding against future cyber attacks.
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    <Card sx={{  }} className=''>
                                        <CardContent>
                                            <div className='text-white'>
                                                <CreditCard />
                                            </div>
                                            <Typography gutterBottom variant="h5" component="div" className='mt-2'>
                                                Quantum Cryptography (QC)
                                            </Typography>
                                            <Typography gutterBottom variant="body2" component="div" sx={{ color: "#ccd1d1" }} paddingBottom={5} >
                                                Intrust Innovation Labs is steadfast committed to pioneering the evolution of cryptography solutions, fortifying security, and safeguarding against future cyber attacks.
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    <Card sx={{  }} className=''>
                                        <CardContent>
                                            <div className='text-white'>
                                                <CreditCard />
                                            </div>
                                            <Typography gutterBottom variant="h5" component="div" className='mt-2'>
                                                Quantum Safe Suite for Cyber Physical Systems (QSCPS)
                                            </Typography>
                                            <Typography gutterBottom variant="body2" component="div" sx={{ color: "#ccd1d1" }} paddingBottom={5} >
                                                Intrust Innovation Labs is steadfast committed to pioneering the evolution of cryptography solutions, fortifying security, and safeguarding against future cyber attacks.
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Stack>
                                <Stack spacing={2} direction="row" marginY={3}>

                                    <Card sx={{  }} className=''>
                                        <CardContent>
                                            <div className='text-white'>
                                                <CreditCard />
                                            </div>
                                            <Typography gutterBottom variant="h5" component="div" className='mt-2'>
                                                Nature Inspired Cryptography (NIC)
                                            </Typography>
                                            <Typography gutterBottom variant="body2" component="div" sx={{ color: "#ccd1d1" }} paddingBottom={5} >
                                                Intrust Innovation Labs is steadfast committed to pioneering the evolution of cryptography solutions, fortifying security, and safeguarding against future cyber attacks.
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    <Card sx={{  }} className=''>
                                        <CardContent>
                                            <div className='text-white'>
                                                <CreditCard />
                                            </div>
                                            <Typography gutterBottom variant="h5" component="div" className='mt-2'>
                                                Web3
                                            </Typography>
                                            <Typography gutterBottom variant="body2" component="div" sx={{ color: "#ccd1d1" }} paddingBottom={5} >
                                                Intrust Innovation Labs is steadfast committed to pioneering the evolution of cryptography solutions, fortifying security, and safeguarding against future cyber attacks.
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    <Card sx={{  }} className=''>
                                        <CardContent>
                                            <div className='text-white'>
                                                <CreditCard />
                                            </div>
                                            <Typography gutterBottom variant="h5" component="div" className='mt-2'>
                                                Blockchain
                                            </Typography>
                                            <Typography gutterBottom variant="body2" component="div" sx={{ color: "#ccd1d1" }} paddingBottom={5} >
                                                Intrust Innovation Labs is steadfast committed to pioneering the evolution of cryptography solutions, fortifying security, and safeguarding against future cyber attacks.
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Stack>
                            </Grid>

                        </Grid>
                    </Box>
                </Box>
            </Box>

        </>
    )
}

