
import React from 'react'
import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import NavBar from '../../components/NavBar'
import SidenavUsecase from '../../components/SidenavUsecase'
import { CreditCard } from '@material-ui/icons'

export default function Usecase() {

    return (
        <>
            <NavBar title="Cross-Industry Business Cases" />
            <Box marginTop={10}>
                <Box sx={{ display: "flex" }}>
                    <SidenavUsecase />
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Stack spacing={2} direction="row">
                                    <Card sx={{}} className='gradien'>
                                        <CardContent>
                                            <div className='text-white'>
                                                <CreditCard />
                                            </div>
                                            <Typography gutterBottom variant="h5" component="div" className='mt-2'>
                                                Banking & Finance
                                            </Typography>
                                            <Typography gutterBottom variant="body2" component="div" sx={{ color: "#ccd1d1" }} paddingBottom={5} >
                                                Intrust Innovation Labs is steadfast committed to pioneering the evolution of cryptography solutions, fortifying security, and safeguarding against future cyber attacks.
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    <Card sx={{}} className='gradien'>
                                        <CardContent>
                                            <div className='text-white'>
                                                <CreditCard />
                                            </div>
                                            <Typography gutterBottom variant="h5" component="div" className='mt-2'>
                                                Healthcare
                                            </Typography>
                                            <Typography gutterBottom variant="body2" component="div" sx={{ color: "#ccd1d1" }} paddingBottom={5} >
                                                Intrust Innovation Labs is steadfast committed to pioneering the evolution of cryptography solutions, fortifying security, and safeguarding against future cyber attacks.
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    <Card sx={{}} className='gradien'>
                                        <CardContent>
                                            <div className='text-white'>
                                                <CreditCard />
                                            </div>
                                            <Typography gutterBottom variant="h5" component="div" className='mt-2'>
                                                Energy Sector
                                            </Typography>
                                            <Typography gutterBottom variant="body2" component="div" sx={{ color: "#ccd1d1" }} paddingBottom={5} >
                                                Intrust Innovation Labs is steadfast committed to pioneering the evolution of cryptography solutions, fortifying security, and safeguarding against future cyber attacks.
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Stack>
                                <Stack spacing={2} direction="row" marginY={3}>

                                    <Card sx={{}} className='gradien'>
                                        <CardContent>
                                            <div className='text-white'>
                                                <CreditCard />
                                            </div>
                                            <Typography gutterBottom variant="h5" component="div" className='mt-2'>
                                                Transportation
                                            </Typography>
                                            <Typography gutterBottom variant="body2" component="div" sx={{ color: "#ccd1d1" }} paddingBottom={5} >
                                                Intrust Innovation Labs is steadfast committed to pioneering the evolution of cryptography solutions, fortifying security, and safeguarding against future cyber attacks.
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    <Card sx={{}} className='gradien'>
                                        <CardContent>
                                            <div className='text-white'>
                                                <CreditCard />
                                            </div>
                                            <Typography gutterBottom variant="h5" component="div" className='mt-2'>
                                                Information Technology
                                            </Typography>
                                            <Typography gutterBottom variant="body2" component="div" sx={{ color: "#ccd1d1" }} paddingBottom={5} >
                                                Intrust Innovation Labs is steadfast committed to pioneering the evolution of cryptography solutions, fortifying security, and safeguarding against future cyber attacks.
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    <Card sx={{}} className='gradien'>
                                        <CardContent>
                                            <div className='text-white'>
                                                <CreditCard />
                                            </div>
                                            <Typography gutterBottom variant="h5" component="div" className='mt-2'>
                                                Govt. and Defence
                                            </Typography>
                                            <Typography gutterBottom variant="body2" component="div" sx={{ color: "#ccd1d1" }} paddingBottom={5} >
                                                Intrust Innovation Labs is steadfast committed to pioneering the evolution of cryptography solutions, fortifying security, and safeguarding against future cyber attacks.
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Stack>
                                <Stack spacing={2} direction="row" marginY={3}>
                                    <Card sx={{}} className='gradien'>
                                        <CardContent>
                                            <div className='text-white'>
                                                <CreditCard />
                                            </div>
                                            <Typography gutterBottom variant="h5" component="div" className='mt-2'>
                                                Telecom & Data Center
                                            </Typography>
                                            <Typography gutterBottom variant="body2" component="div" sx={{ color: "#ccd1d1" }} paddingBottom={5} >
                                                Intrust Innovation Labs is steadfast committed to pioneering the evolution of cryptography solutions, fortifying security, and safeguarding against future cyber attacks.
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    <Card sx={{}} className='gradien'>
                                        <CardContent>
                                            <div className='text-white'>
                                                <CreditCard />
                                            </div>
                                            <Typography gutterBottom variant="h5" component="div" className='mt-2'>
                                                Emerging Industries
                                            </Typography>
                                            <Typography gutterBottom variant="body2" component="div" sx={{ color: "#ccd1d1" }} paddingBottom={5} >
                                                Intrust Innovation Labs is steadfast committed to pioneering the evolution of cryptography solutions, fortifying security, and safeguarding against future cyber attacks.
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    <Card sx={{}} className='gradien'>
                                        <CardContent>
                                            <div className='text-white'>
                                                <CreditCard />
                                            </div>
                                            <Typography gutterBottom variant="h5" component="div" className='mt-2'>
                                                Water & Waste Digital
                                            </Typography>
                                            <Typography gutterBottom variant="body2" component="div" sx={{ color: "#ccd1d1" }} paddingBottom={5} >
                                                Intrust Innovation Labs is steadfast committed to pioneering the evolution of cryptography solutions, fortifying security, and safeguarding against future cyber attacks.
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Stack>
                            </Grid>

                        </Grid>
                    </Box>
                </Box>
            </Box>

        </>
    )
}

