// src/components/Parallax.js
import React, { useRef, useEffect } from 'react';
import './Parallax.css';

const Parallax = () => {
    const containerRef = useRef(null);

    useEffect(() => {
        const handleMouseMove = (e) => {
            const container = containerRef.current;
            const { clientX, clientY } = e;

            const containerRect = container.getBoundingClientRect();
            const x = ((clientX - containerRect.left) / containerRect.width) * 100;
            const y = ((clientY - containerRect.top) / containerRect.height) * 100;

            container.style.backgroundPosition = `${x}% ${y}%`;
        };

        const container = containerRef.current;
        container.addEventListener('mousemove', handleMouseMove);

        return () => {
            container.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <div className="parallax-container" ref={containerRef}>
            <div className="parallax-content">
                <h1>Parallax Mousemove Effect</h1>
                <p>Move your mouse to see the parallax effect!</p>
            </div>
        </div>
    );
};

export default Parallax;
