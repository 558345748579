import React from 'react'
import SideNav from '../components/Sidenav'
import NavBar from '../components/NavBar'
import { Box } from '@mui/material'

export default function About() {
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <h1>About</h1>
        </Box>
      </Box>
    </>
  )
}