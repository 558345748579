import React from 'react';
import { Box, Button, Stack } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { deepPurple } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';



const primary = deepPurple[500]; // #f44336
const Landing = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate()
  const [images, setImages] = useState([
    '../images/quantum-1.jpg',
    '../images/quantum-2.jpg',
    '../images/quantum-3.jpg',
  ]);
  const [randomImageUrl, setRandomImageUrl] = useState('');
  const [backgroundImage, setBackgroundImage] = useState(`url(${randomImageUrl})`);
  useEffect(() => {
    const setRandomImage = () => {
      const randomIndex = Math.floor(Math.random() * images.length);
      const randomImage = images[randomIndex];
      setBackgroundImage(`url(${randomImage})`);
    };
    // Set a random image when the component mounts
    setRandomImage();

    // const intervalId = setInterval(setRandomImage, 3000);
    // Clean up the interval when the component is unmounted
    // return () => clearInterval(intervalId);
  }, []);
  return (
    <div
      style={{
        textAlign: 'center',
        padding: isSmallScreen ? '20px' : '50px',
        backgroundColor: primary,
        backgroundSize: 'cover',
        height: '100vh',
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        {/* <img src="../images/logo.jpg" alt="IntrustLabs-Logo" className='my-5'/> */}
        <h1 className='text-white'>INTRUST INNOVATION LABS</h1>
        <h3 className='text-warning mt-2'>Unhackable Horizon: Witness the Future of  Digital Security</h3>

        <Box marginTop={isSmallScreen ? 1 : 3}>
          <Box sx={{ display: "flex" }}>
            <Box component="main" sx={{ flexGrow: 1, p: isSmallScreen ? 1 : 3 }}>
              <Stack spacing={2} direction={isSmallScreen ? "column" : "row"}>
                
                <Button variant="contained" size="large" color="primary">
                  <span className={isSmallScreen ? 'p-2' : 'p-3'} onClick={() => navigate('/products')}>
                    PRODUCTS
                  </span>
                </Button>
                <Button variant="contained" size="large" color="success">
                  <span className={isSmallScreen ? 'p-2' : 'p-3'} onClick={() => navigate('/platforms')}>
                  PLATFORM
                  </span>
                </Button>
                <Button variant="contained" size="large" color="secondary">
                  <span className={isSmallScreen ? 'p-2' : 'p-3'} onClick={() => navigate('/testsuite')}>
                  TEST SUITE
                  </span>
                </Button>
                <Button variant="contained" size="large" color="info">
                  <span className={isSmallScreen ? 'p-2' : 'p-3'} onClick={() => navigate('/usecase')}>
                  USE CASES
                  </span>
                </Button>
                <Button variant="contained" size="large" color="error">
                  <span className={isSmallScreen ? 'p-2' : 'p-3'} onClick={() => navigate('/security-audit')}>
                  SECURITY AUDIT
                  </span>
                </Button>

              </Stack>
            </Box>
          </Box>
        </Box> 
      </Box>
    </div>
  );
};

export default Landing;
