import React from 'react'
import Sidenav from '../components/Sidenav'
import NavBar from '../components/NavBar'
import { Box } from '@mui/material'
import List from '../components/settings/List'

export default function Settings() {
  return (
    <>
      <NavBar  title="" />
      <Box marginTop={10}>
        <Box sx={{ display: "flex" }}>
          <Sidenav />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <h1>Settings</h1>
            <List/>
          </Box>
        </Box>
      </Box>
    </>
  )
}