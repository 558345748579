import { create } from 'zustand'

const useBearStore = create((set) => ({
    isLoggedIn: localStorage.getItem('isLoggedIn') === 'true' || false,
    login: () => set({ isLoggedIn: true }),
    logout: () => set({ isLoggedIn: false }),
    setIsLoggedIn: (arg) => set({ isLoggedIn: arg }),
    userData: [],
    setUserData: (arg) => set({ setUserData: arg }),
    sideNavOpen: localStorage.getItem('sideNavOpen') === 'true' || false,
    setSideNavOpen: (arg) => set({ sideNavOpen: arg }),
    productsOpen: localStorage.getItem('productsOpen') === 'true' || false,
    setProductsOpen: (arg) => set({ productsOpen: arg }),
    platformsOpen: localStorage.getItem('platformsOpen') === 'true' || false,
    setPlatformsOpen: (arg) => set({ platformsOpen: arg }),
    testsuiteOpen: localStorage.getItem('testsuiteOpen') === 'true' || false,
    setTestsuiteOpen: (arg) => set({ testsuiteOpen: arg }),
    useCaseOpen: localStorage.getItem('useCaseOpen') === 'true' || false,
    setUseCaseOpen: (arg) => set({ useCaseOpen: arg }),
    sequrityOpen: localStorage.getItem('sequrityOpen') === 'true' || false,
    sequrityOpen: (arg) => set({ sequrityOpen: arg }),
}))

// Add a listener to persist the state to localStorage
useBearStore.subscribe(
    (state) => {
        localStorage.setItem('isLoggedIn', state.isLoggedIn);
        localStorage.setItem('sideNavOpen', state.sideNavOpen);
        localStorage.setItem('productsOpen', state.productsOpen);
        localStorage.setItem('testsuiteOpen', state.testsuiteOpen);
        localStorage.setItem('useCaseOpen', state.testsuiteOpen);
        localStorage.setItem('sequrityOpen', state.testsuiteOpen);
    },
    (state) => ({
        isLoggedIn: state.isLoggedIn,
        sideNavOpen: state.sideNavOpen,
        productsOpen: state.productsOpen,
        platformsOpen: state.platformsOpen,
        testsuiteOpen: state.testsuiteOpen,
        useCaseOpen: state.useCaseOpen,
        sequrityOpen: state.sequrityOpen,
    })
);


export default useBearStore;