import React from 'react'
import SidenavProducts from '../../components/SidenavProducts'
import NavBar from '../../components/NavBar'
import { Box, Card, CardActions, CardContent, Grid, Stack, Typography, Button } from '@mui/material'
import { CreditCard } from '@material-ui/icons'

export default function Web3() {
  return (
    <>
            <NavBar  title="Quantum Safe Security Suites" />
      <Box marginTop={10}>
        <Box sx={{ display: "flex" }}>
          <SidenavProducts />

        
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <h1>Web3</h1>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack spacing={2} direction="row">
                    <Card sx={{ maxWidth: 33 + "%" }} className='gradien'>
                      <CardContent>
                        <div className='text-white'>
                          <CreditCard />
                        </div>
                        <Typography gutterBottom variant="h5" component="div" className='mt-2'>
                        Web3
                        </Typography>
                        <Typography gutterBottom variant="body2" component="div" sx={{ color: "#ccd1d1" }}  >
                          Intrust Innovation Labs is steadfast committed to pioneering the evolution of cryptography solutions, fortifying security, and safeguarding against future cyber attacks.
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button size="small">Learn More</Button>
                      </CardActions>

                    </Card>
                    <Card sx={{ maxWidth: 33 + "%" }} className='gradien'>
                      <CardContent>
                        <div className='text-white'>
                          <CreditCard />
                        </div>
                        <Typography gutterBottom variant="h5" component="div" className='mt-2'>
                        Web3
                        </Typography>
                        <Typography gutterBottom variant="body2" component="div" sx={{ color: "#ccd1d1" }}  >
                          Intrust Innovation Labs is steadfast committed to pioneering the evolution of cryptography solutions, fortifying security, and safeguarding against future cyber attacks.
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button size="small">Learn More</Button>
                      </CardActions>
                    </Card>
                    <Card sx={{ maxWidth: 33 + "%" }} className='gradien'>
                      <CardContent>
                        <div className='text-white'>
                          <CreditCard />
                        </div>
                        <Typography gutterBottom variant="h5" component="div" className='mt-2'>
                        Web3
                        </Typography>
                        <Typography gutterBottom variant="body2" component="div" sx={{ color: "#ccd1d1" }}  >
                          Intrust Innovation Labs is steadfast committed to pioneering the evolution of cryptography solutions, fortifying security, and safeguarding against future cyber attacks.
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button size="small">Learn More</Button>
                      </CardActions>
                    </Card>
                  </Stack>
                  <Stack spacing={2} direction="row" marginY={3}>

                    <Card sx={{ maxWidth: 33 + "%" }} className='gradien'>
                      <CardContent>
                        <div className='text-white'>
                          <CreditCard />
                        </div>
                        <Typography gutterBottom variant="h5" component="div" className='mt-2'>
                        Web3
                        </Typography>
                        <Typography gutterBottom variant="body2" component="div" sx={{ color: "#ccd1d1" }}  >
                          Intrust Innovation Labs is steadfast committed to pioneering the evolution of cryptography solutions, fortifying security, and safeguarding against future cyber attacks.
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button size="small">Learn More</Button>
                      </CardActions>
                    </Card>
                    <Card sx={{ maxWidth: 33 + "%" }} className='gradien'>
                      <CardContent>
                        <div className='text-white'>
                          <CreditCard />
                        </div>
                        <Typography gutterBottom variant="h5" component="div" className='mt-2'>
                        Web3
                        </Typography>
                        <Typography gutterBottom variant="body2" component="div" sx={{ color: "#ccd1d1" }}  >
                          Intrust Innovation Labs is steadfast committed to pioneering the evolution of cryptography solutions, fortifying security, and safeguarding against future cyber attacks.
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button size="small">Learn More</Button>
                      </CardActions>
                    </Card>
                    <Card sx={{ maxWidth: 33 + "%" }} className='gradien'>
                      <CardContent>
                        <div className='text-white'>
                          <CreditCard />
                        </div>
                        <Typography gutterBottom variant="h5" component="div" className='mt-2'>
                        Web3
                        </Typography>
                        <Typography gutterBottom variant="body2" component="div" sx={{ color: "#ccd1d1" }}  >
                          Intrust Innovation Labs is steadfast committed to pioneering the evolution of cryptography solutions, fortifying security, and safeguarding against future cyber attacks.
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button size="small">Learn More</Button>
                      </CardActions>
                    </Card>
                   
                  </Stack>
                </Grid>

              </Grid>

          </Box>
        </Box>
      </Box>
    </>
  )
}