import React from 'react'
import Sidenav from '../components/Sidenav'
import NavBar from '../components/NavBar'
import { Box} from '@mui/material'


import "../Dashboard.css"

import ListItems from '../poc/ListItems';

export default function Dashboard() {
  return (
    <>
      <NavBar  title="" />

      <Box marginTop={10}>
        <Box sx={{ display: "flex" }}>
          <Sidenav />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>      
            <ListItems />
          </Box>
        </Box>
      </Box >
    </>
  )
}